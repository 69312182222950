import React from "react"
import { getNewestDate, generatePath, MAX_COMBINATIONS } from "../lib/utils"
import Layout from "../components/Layout"
import CompareBody from "../components/CompareBody"
import PageTitle from "../components/PageTitle"
import CompareHeader from "../components/CompareHeader"
import CompareSidebarSearch from "../components/CompareSidebarSearch"
import Seo from "../components/Seo/Seo"
import { graphql } from "gatsby"
import Breadcrumb from "../components/Breadcrumb"

export const query = graphql`
    query ($slug: [String!], $location: String, $locale: String) {
        datoCmsLocation(websiteUrl: {eq: $location}) {
            country {
                countryName
                countryCode
            }
            flagImage {
                gatsbyImageData(
                    imgixParams: {colors: 10, h: "20", w: "20", fit: "crop", auto: "format", mask: "ellipse"}
                    placeholder: BLURRED
                )
            }
            compareSidebarInfoNode {
                childMarkdownRemark {
                html
                }
            }
            compareMetaTitleTemplate
            compareMetaDescriptionTemplate
            compareProductSeparator
            compareTitleAfterText
            compareTitleBeforeText
            compareSubtitleAfterText
            compareSubtitleBeforeText
            currencySymbol
            dimensionsSymbol
            weightSymbol
            timezone
        }
        allDatoCmsMattress(
            filter: {
                locale: {eq: $locale}, 
                slug: {in: $slug}, 
                location: {
                    websiteUrl: {eq: $location}
                    }
                },
                sort: {
                    fields: slug, 
                    order: ASC
                }
            ) {
            nodes {
                mattressImage {
                    gatsbyImageData(
                        imgixParams: {auto: "format", fit: "crop", h: "90", w: "180", colors: 10, mask: "corners", cornerRadius: "10,10,10,10"}
                        placeholder: BLURRED
                    )
                }
                ...FragmentMattressFields
            }
        }
        allMattresses: allDatoCmsMattress(filter: {locale: {eq: $locale}, location: {websiteUrl: {eq: $location}}}) {
            nodes {
                slug
                name
                location {
                    websiteUrl
                }
                mattressImage {
                    gatsbyImageData(
                        imgixParams: {auto: "format", fit: "crop", h: "35", w: "70", colors: 10, mask: "corners", cornerRadius: "10,10,10,10"}
                        placeholder: BLURRED
                    )
                }
            }
        }
        datoCmsTranslation(locale: {eq: $locale}) {
            ...FragmentTranslationFields
        }
    }`

const Compare = ({ data, pageContext }) => {
    let title_vs = data.datoCmsLocation.compareProductSeparator
    let title_before = data.datoCmsLocation.compareTitleBeforeText
    let subtitle_after = data.datoCmsLocation.compareSubtitleAfterText
    let subtitle_before = data.datoCmsLocation.compareSubtitleBeforeText

    let mattress_title = []

    let allDates = []
    let all_compare_mattresses_sizes = []
    data.allDatoCmsMattress.nodes.forEach((mattress, i) => {
        mattress_title.push(mattress.name)
        allDates.push(mattress.meta.updatedAt)

        //need to push the brand updated at date too
        allDates.push(mattress.brand.meta.updatedAt)

        let buttons = mattress.buttons
        for (let i = 0; i < buttons.length; i++) {
            let is_repeated_size = all_compare_mattresses_sizes.indexOf(buttons[i].size.sizeName)
            if (is_repeated_size === -1) {
                //not found so lets add the size
                all_compare_mattresses_sizes.push(buttons[i].size.sizeName)
            }
        }
    })

    let all_mattresses_title = mattress_title.join(' ' + title_vs + ' ')

    let reverse_mattress_title = mattress_title.reverse()
    let reverse_all_mattress_title = reverse_mattress_title.join(' ' + title_vs + ' ')

    const newestDate = getNewestDate(allDates, data.datoCmsLocation.timezone, pageContext.locale)

    let pageTitle = title_before + ' ' + all_mattresses_title
    let pageSubtitle = `${subtitle_before} ${reverse_all_mattress_title} ${data.datoCmsTranslation.mattresses} ${subtitle_after}`

    let metaTitle = data.datoCmsLocation.compareMetaTitleTemplate
    let meta_description = data.datoCmsLocation.compareMetaDescriptionTemplate
    let meta_image = null
    let meta_slug = generatePath(
        pageContext.slug,
        pageContext.locale,
        pageContext.countryCode,
        data.datoCmsTranslation.slugMattress
    )

    metaTitle = metaTitle.replace('%%pagetitle%%', all_mattresses_title)
    metaTitle = metaTitle.replace('%%currentyear%%', newestDate.year)
    
    meta_description = meta_description.replace('%%pagetitle%%', all_mattresses_title)

    const breadcrumbData = [
        {
            'link': `/${data.datoCmsLocation.country.countryCode}`,
            'image': data.datoCmsLocation.flagImage,
            'label': data.datoCmsLocation.country.countryName
        },
        {
            'link': null,
            'label': data.datoCmsTranslation.comparison
        }
    ]

    return (
        <Layout
            locale={pageContext.locale}
            queryTranslation={data.datoCmsTranslation}
            countryCode={pageContext.countryCode}
            footerText={data.datoCmsLocation.compareSidebarInfoNode}
            countryName={data.datoCmsLocation.country.countryName}
            flagImage={data.datoCmsLocation.flagImage}
        >
            <Seo
                title={metaTitle}
                description={meta_description}
                image={meta_image}
                pathname={meta_slug}
                article
            />

            <div className="bg-zone-1">
                <div className="container mx-auto flex flex-col lg:flex-row justify-between">
                    <main className={`${pageContext.slug.length > 2 ? 'w-full' : 'w-full lg:w-3/4 lg:pr-8'}`}>
                        <Breadcrumb
                            items={breadcrumbData}
                            homeLabel={data.datoCmsTranslation.home}
                        />

                        <PageTitle
                            newestDate={newestDate}
                            title={pageTitle}
                            subTitle={pageSubtitle}
                            link={null}
                            category={data.datoCmsTranslation.comparison}
                            lastUpdatedOn={data.datoCmsTranslation.lastUpdatedOn}
                        />

                        <CompareHeader
                            mattresses={data.allDatoCmsMattress.nodes}
                            rawSlugs={pageContext.slug}
                            countryCode={pageContext.countryCode}
                        />

                        <CompareBody
                            mattresses={data.allDatoCmsMattress.nodes}
                            location={data.datoCmsLocation}
                            sizes={all_compare_mattresses_sizes}
                        />

                    </main>
                    {pageContext.slug.length < MAX_COMBINATIONS && (
                        <CompareSidebarSearch
                            allMattresses={data.allMattresses.nodes}
                            countryCode={pageContext.countryCode}
                            rawSlugs={pageContext.slug}
                            className={`px-2 md:px-4 xl:px-0 w-full lg:w-1/4`}
                        />
                    )}
                </div>
            </div>
        </Layout>
    )
}

export default Compare

