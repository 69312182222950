import React, { useContext } from "react"
import TranslationContext from "../lib/translationContext"
import { getPromotionLink, generatePath } from "../lib/utils"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"
import srcIconExternal from "../images/icon-external.svg"

const CompareHeader = ({
    mattresses,
    rawSlugs,
    countryCode = null,
}) => {

    const { queryTranslation, locale } = useContext(TranslationContext);

    function removeProduct(slug) {
        const mattressesSlug = rawSlugs.filter(item => item !== slug)

        navigate(
            generatePath(mattressesSlug, locale, countryCode, queryTranslation.slugMattress),
            {
                state: { mattressesSlug },
            }
        )
    }

    const iconExternal = <img
        src={srcIconExternal}
        alt={queryTranslation.altExternalLink}
        width="16"
        height="16"
        className="inline-block ml-3 -mt-1"
    />
    const iconClose = <svg
        className="fill-current text-zone-1 hover:text-zone-5"
        aria-hidden="true"
        width="20"
        height="20"
        focusable="false"
        data-prefix="fas"
        data-icon="times-circle"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
    >
        <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path>
    </svg>

    return (
        <>
            <div className="">
                <table className="w-full border-collapse sm:table-fixed md:text-center table-header-compare">
                    <tbody>
                        <tr className="">
                            {mattresses.map((mattress, i) => {
                                let affiliateLink = getPromotionLink(mattress)
                                const image = <GatsbyImage image={getImage(mattress.mattressImage)} alt={mattress.name} className="product-blend" />

                                return (
                                    <td
                                        className="block w-full p-2 md:table-cell md:w-auto"
                                        key={`compare-header-picture-key-${i}`}
                                    >
                                        <div className="relative flex flex-row-reverse justify-around items-center md:block">
                                            <button
                                                aria-label={queryTranslation.removeItem}
                                                onClick={() => removeProduct(mattress.slug)}
                                                className="z-20 border border-zone-1 rounded-xl sm:hidden"
                                            >
                                                {iconClose}
                                            </button>
                                            {affiliateLink ? (
                                                <>
                                                    {/*eslint-disable-next-line*/}
                                                    <a
                                                        href={affiliateLink}
                                                        target="_blank"
                                                        rel="nofollow noopener sponsored"
                                                        id={`AffTitleClick-img-${i}`}
                                                        className=""
                                                    >{image}</a>
                                                </>
                                            ) : (
                                                <>{image}</>
                                            )}
                                        </div>
                                    </td>
                                )
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="duration-100 top-0 sticky z-10 border-b border-dashed border-zone-2">
                <table className="w-full border-collapse sm:table-fixed md:text-center table-header-compare">
                    <tbody>
                        <tr className="">
                            {mattresses.map((mattress, i) => {
                                let affiliateLink = getPromotionLink(mattress)
                                const htmlItem = <h3 className="font-grotesk font-semibold text-lg text-zone-1">
                                    {mattress.name} {iconExternal}
                                </h3>

                                return (
                                    <td
                                        className="block w-full p-2 md:table-cell md:w-auto"
                                        key={`compare-header-title-key-${i}`}
                                    >
                                        <div className="inline-flex">
                                            {affiliateLink ? (
                                                <>
                                                    {/*eslint-disable-next-line*/}
                                                    <a
                                                        href={affiliateLink}
                                                        target="_blank"
                                                        rel="nofollow noopener sponsored"
                                                        id={`AffTitleClick-name-${i}`}
                                                        className="underline hover:no-underline"
                                                    >{htmlItem}</a>
                                                </>
                                            ) : (<>{htmlItem}</>)}
                                            <button
                                                title={queryTranslation.removeItem}
                                                aria-label={queryTranslation.removeItem}
                                                onClick={() => removeProduct(mattress.slug)}
                                                className="relative -right-4 z-20 border border-zone-1 rounded-xl hidden sm:block"
                                            >
                                                {iconClose}
                                            </button>
                                        </div>
                                    </td>
                                )
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default CompareHeader
